import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { updateTaxDeclarationFormField } from 'redux/actions';
import { Field, stringValue } from '@agoy/document';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';
import { Checkbox, Input, Radio, RadioOption } from './Input';

const CheckBoxRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  input {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
    width: 98%;
  }
`;

const InnerWrapper = styled.div`
  flex: 1;
  border: 1px solid #000;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;

  &.hideRightBorder {
    border-right: none;
  }

  div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

type ToSkatteverketProps = {
  toSkatteverketTitle: Field;
  checkboxes: Field[];
  toSkatteverketText: Field;
};

export const ToSkatteverket = ({
  toSkatteverketTitle,
  checkboxes,
  toSkatteverketText,
}: ToSkatteverketProps) => {
  const { formatMessage } = useIntl();
  return (
    <CheckBoxRadioWrapper>
      <Input field={toSkatteverketTitle} keyValue="toSkatteverketTitle" title />
      <InnerWrapper>
        {checkboxes.map((checkbox, index) => (
          <Checkbox
            field={checkbox}
            keyValue={`toSkatteverketCheckbox${index + 1}`}
            key={`toSkatteverketCheckbox${index + 1}`}
          >
            {formatMessage({
              id: `tax.coverLetter.toSkatteverketCheckbox${index + 1}`,
            })}
          </Checkbox>
        ))}
      </InnerWrapper>
      <Input field={toSkatteverketText} keyValue="toSkatteverketText" />
    </CheckBoxRadioWrapper>
  );
};

type SigningOFIncomeDeclarationProps = {
  signatureTitle: Field;
  signatureOfIncomeDeclaration: Field;
  signatureText: Field;
};

export const SigningOFIncomeDeclaration = ({
  signatureTitle,
  signatureOfIncomeDeclaration,
  signatureText,
}: SigningOFIncomeDeclarationProps) => {
  const dispatch = useDispatch();
  return (
    <CheckBoxRadioWrapper>
      <Input field={signatureTitle} keyValue="signatureTitle" title />
      <InnerWrapper className="hideRightBorder">
        <Radio
          defaultValue={stringValue(signatureOfIncomeDeclaration)}
          onChange={(value: string) => {
            dispatch(
              updateTaxDeclarationFormField(
                'CL',
                'signatureOfIncomeDeclaration',
                value
              )
            );
          }}
        >
          <RadioOption
            label="Byrån signerar deklarationen åt deklaranten"
            value="1"
          />
          <RadioOption
            label="Deklaranten signerar själv via Skatteverkets e-tjänster"
            value="2"
          />
          <RadioOption
            label="Deklaranten ska skriva under och lämna
in deklarationsblanketten till byrån"
            value="3"
          />
        </Radio>
      </InnerWrapper>
      <Input field={signatureText} keyValue="signatureText" />
    </CheckBoxRadioWrapper>
  );
};

const TaxTableWrapper = styled.div`
  table {
    margin: ${({ theme }) => theme.spacing(0.5)}px 0;
    width: 100%;
    border-collapse: collapse;

    td {
      border: 1px solid #000;
      padding-top: ${({ theme }) => theme.spacing(1)}px;
      padding-bottom: ${({ theme }) => theme.spacing(0.5)}px;
      padding-left: ${({ theme }) => theme.spacing(1)}px;
    }

    td:nth-child(1) {
      width: 75%;
    }

    td:nth-child(2) {
      padding-left: ${({ theme }) => theme.spacing(0.5)}px;
      padding-right: ${({ theme }) => theme.spacing(0.5)}px;
    }

    input {
      text-align: right;
    }
  }
`;

type RowProps = {
  title: string;
  keyValue: string;
  field: Field;
};

const Row = ({ title, keyValue, field }: RowProps) => {
  return (
    <tr>
      <td>
        <Typography margin="none">{title}</Typography>
      </td>
      <td>
        <Input field={field} keyValue={keyValue} />
      </td>
    </tr>
  );
};

type TaxTableProps = {
  estimatedFinalTax: Field;
  estimatedTaxRefund: Field;
  fTax: Field;
};

export const TaxTable = ({
  estimatedFinalTax,
  estimatedTaxRefund,
  fTax,
}: TaxTableProps) => {
  return (
    <TaxTableWrapper>
      <table>
        <Row
          title="Beräknad slutlig skatt"
          keyValue="estimatedFinalTax"
          field={estimatedFinalTax}
        />
        <Row
          title="Beräknad skatteåterbäring"
          keyValue="estimatedTaxRefund"
          field={estimatedTaxRefund}
        />
        <Row title="F-skatt" keyValue="fTax" field={fTax} />
      </table>
    </TaxTableWrapper>
  );
};

const ImageContainer = styled.div`
  display: flex;
  width: 370px;
  height: 100px;
  overflow: hidden;
  align-items: flex-start;
  justify-content: end;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const LogoOrName = ({
  logo,
  orgName,
}: {
  logo: string | undefined;
  orgName: string | undefined;
}) =>
  logo && logo.length !== 0 ? (
    <ImageContainer>
      <Img src={logo} alt="Logotype för företaget" />
    </ImageContainer>
  ) : (
    <Typography variant="h2">{orgName}</Typography>
  );
