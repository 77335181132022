import { mapRecord } from '@agoy/common';
import {
  AgoyDocumentNode,
  AgoyDocumentStructurePart,
  abs,
  id,
  max,
  min,
  multiply,
  or,
} from '@agoy/document';
import {
  TaxDeclarationFormData,
  TaxDeclarationFormDataConfig,
  TaxDeclarationFormDefinition,
  TaxDeclarationFormPartData,
  TaxDeclarationFormStructure,
  isTaxDeclarationCoverLetter,
  isTaxDeclarationFormPartData,
} from '../types';
import { TaxDeclarationHelpStructure } from '../types/help';

export const mapFormId = (
  form: TaxDeclarationFormDefinition,
  partId: string
): string => {
  const part = form.parts?.find(
    (part) => part.type === 'form' && part.id.startsWith(`${partId}-`)
  );
  if (part?.type !== 'form') {
    throw new Error(`Part ${partId} not found in form definition`);
  }
  return part.id;
};

/**
 * According to Skatteverket people who turn 100 have “-” replaced with “+” in their personal number.
 * All people born before 1924 are unlikely to be customers
 * and we do not take that into account.
 * With this info in R43 we assume that any YYDDMM-XXXX personal numbers
 * starting with 0 or 1 are people born in 2000s.
 */
export const calculatePercent = (orgNumber: string) => {
  let normalizedOrgNumber = orgNumber.replace('-', '');
  if (normalizedOrgNumber.length === 10) {
    const prependValue = ['0', '1'].includes(normalizedOrgNumber.charAt(0))
      ? '20'
      : '19';
    normalizedOrgNumber = `${prependValue}${normalizedOrgNumber}`;
  }

  const year = Number(normalizedOrgNumber.slice(0, 4));

  if (year <= 1937) return 0;
  if (year >= 1938 && year <= 1956) return 0.1;

  return 0.25;
};

/**
 * Creates the initial document
 *
 * Use the `toConfig` instead of `toInitial` and `toStructure`.
 *
 * @param initial
 * @param form
 */
export function toInitial(
  initial: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition
): TaxDeclarationFormData;

/**
 * Creates the initial document.
 *
 * This overload should not be used directly, use the `toConfig` instead.
 *
 * @param initial
 * @param form
 * @param additionalStructure
 * @param additionalPages
 */
export function toInitial<
  T extends Record<
    string,
    AgoyDocumentStructurePart & { partType: 'additionalPages' }
  >
>(
  initial: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition,
  additionalStructure: T,
  additionalPages: { [K in keyof T]: AgoyDocumentNode<T[K]> }
);

/**
 * Implementation of the toInitial function.
 *
 * @param initial
 * @param form
 * @param additionalStructure
 * @param additionalPages
 * @returns
 */
export function toInitial<
  T extends Record<
    string,
    AgoyDocumentStructurePart & { partType: 'additionalPages' }
  >
>(
  initial: TaxDeclarationFormData,
  form: TaxDeclarationFormDefinition,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  additionalStructure?: T,
  additionalPages?: { [K in keyof T]: AgoyDocumentNode<T[K]> }
): TaxDeclarationFormData {
  return {
    ...initial,
    ...mapRecord(form.externalDocuments, (doc) =>
      doc.multiple ? [] : undefined
    ),
    ...(form.relatedClient
      ? mapRecord(form.relatedClient, () => undefined)
      : {}),
    ...additionalPages,
  };
}

export function toStructure(
  doc: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition
): TaxDeclarationFormStructure;

export function toStructure(
  doc: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition,
  help: Record<string, TaxDeclarationHelpStructure> | undefined
): TaxDeclarationFormStructure;

export function toStructure<
  T extends Record<
    string,
    AgoyDocumentStructurePart & { partType: 'additionalPages' }
  >
>(
  doc: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition,
  help: Record<string, TaxDeclarationHelpStructure> | undefined,
  additionalStructure: T
): TaxDeclarationFormStructure;

export function toStructure(
  doc: TaxDeclarationFormData,
  form: TaxDeclarationFormDefinition,
  help?: Record<string, TaxDeclarationHelpStructure> | undefined,
  additionalStructure?:
    | undefined
    | Record<
        string,
        AgoyDocumentStructurePart & { partType: 'additionalPages' }
      >
): TaxDeclarationFormStructure {
  let structure = Object.keys(doc).reduce(
    (
      structure: TaxDeclarationFormStructure,
      key
    ): TaxDeclarationFormStructure => {
      const part = doc[key];
      if (typeof part !== 'object' || Array.isArray(part)) {
        return structure;
      }
      if (
        isTaxDeclarationFormPartData(part) ||
        isTaxDeclarationCoverLetter(part)
      ) {
        const { fields, externalFields, sru, derivedFields } = part;

        structure.children[key] = {
          type: 'part' as const,
          partType: 'form' as const,
          children: {
            fields: Object.keys(fields).reduce(
              (partStructure, fieldKey) => {
                partStructure.children[fieldKey] = {
                  type: 'field' as const,
                };
                return partStructure;
              },
              { type: 'part' as const, children: {} }
            ),
            ...(externalFields
              ? {
                  externalFields: Object.keys(externalFields).reduce(
                    (partStructure, fieldKey) => {
                      partStructure.children[fieldKey] = {
                        type: 'field' as const,
                      };
                      return partStructure;
                    },
                    { type: 'part' as const, children: {} }
                  ),
                }
              : {}),
            sru: Object.keys(sru).reduce(
              (partStructure, fieldKey) => {
                partStructure.children[fieldKey] = {
                  type: 'field' as const,
                };
                return partStructure;
              },
              {
                type: 'part' as const,
                children: {},
              }
            ),
            ...(derivedFields
              ? {
                  derivedFields: Object.keys(derivedFields).reduce(
                    (partStructure, fieldKey) => {
                      partStructure.children[fieldKey] = {
                        type: 'field' as const,
                      };
                      return partStructure;
                    },
                    { type: 'part' as const, children: {} }
                  ),
                }
              : {}),
            ...(help?.[key] ? { help: help[key] } : {}),
          },
        };
      }
      return structure;
    },
    {
      type: 'document' as const,
      children: mapRecord(form.externalDocuments, (doc) =>
        doc.multiple
          ? {
              type: 'externalDocuments' as const,
              documentType: doc.documentType,
              financialYear: doc.financialYear,
              relationType: doc.relationType,
            }
          : {
              type: 'externalDocument' as const,
              documentType: doc.documentType,
              financialYear: doc.financialYear,
              relationType: doc.relationType,
            }
      ),
    }
  );

  if (additionalStructure) {
    structure = {
      ...structure,
      children: {
        ...structure.children,
        ...additionalStructure,
      },
    };
  }
  if (form.relatedClient) {
    structure = {
      ...structure,
      children: {
        ...structure.children,
        ...mapRecord(form.relatedClient, (client, name) => ({
          type: 'relatedClient' as const,
          information: client.information,
          name,
        })),
      },
    };
  }

  return structure;
}

/**
 * Creates the initial document and definition
 *
 * @param initial Initial document data
 * @param form From definition
 */
export function toConfig(
  initial: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition
): TaxDeclarationFormDataConfig;

/**
 * Creates the initial document and definition with
 * helpers.
 *
 * @param initial Initial document data
 * @param form From definition
 */

export function toConfig(
  initial: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition,
  help: Record<string, TaxDeclarationHelpStructure>
): TaxDeclarationFormDataConfig;

/**
 * Creates the initial document and definition with
 * optional helpers and additional pages.
 *
 * @param initial
 * @param form
 * @param help
 * @param additionalStructure
 * @param additionalPages
 */
export function toConfig<
  T extends Record<
    string,
    AgoyDocumentStructurePart & { partType: 'additionalPages' }
  >
>(
  initial: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition,
  help: Record<string, TaxDeclarationHelpStructure> | undefined,
  additionalStructure: T,
  additionalPages: { [K in keyof T]: AgoyDocumentNode<T[K]> }
): TaxDeclarationFormDataConfig;

/**
 * Creates the initial document and definition with
 * optional helpers and additional pages.
 *
 * This is the implementation.
 *
 * @param initial
 * @param form
 * @param help
 * @param additionalStructure
 * @param additionalPages
 * @returns
 */
export function toConfig<
  T extends Record<
    string,
    AgoyDocumentStructurePart & { partType: 'additionalPages' }
  >
>(
  initial: Record<string, TaxDeclarationFormPartData>,
  form: TaxDeclarationFormDefinition,
  help?: Record<string, TaxDeclarationHelpStructure>,
  additionalStructure?: T,
  additionalPages?: { [K in keyof T]: AgoyDocumentNode<T[K]> }
): TaxDeclarationFormDataConfig {
  return {
    initial:
      additionalStructure && additionalPages
        ? toInitial(initial, form, additionalStructure, additionalPages)
        : toInitial(initial, form),
    definition: additionalStructure
      ? toStructure(initial, form, help, additionalStructure)
      : toStructure(initial, form, help),
  };
}

// Below are IDs that are used to reference data from the Annual Report

/**
 * References data from the balanceSheet table of the Annual Report for the CURRENT selected year.
 * Now the reference should be based on the elementNamn Bolagsverket taxonomy element.
 */
export const balanceSheetRef = (reference: string, external = false): string =>
  id(`${external ? 'annualReport.' : ''}balanceSheet.${reference}.year`);

/**
 * References data from the incomeStatement table of the Annual Report for the CURRENT selected year.
 * Now the reference should be based on the elementNamn Bolagsverket taxonomy element.
 */
export const incomeStatementRef = (
  reference: string,
  external = false
): string =>
  id(`${external ? 'annualReport.' : ''}incomeStatement.${reference}.year`);

/**
 * Multiplies the referenced value by -1. This is applied when the tax form cell has a
 * minus sign in front of it.
 */
export const negative = (reference: string): string => multiply(-1, reference);

/**
 * Returns the absolute value. Ex: -2 or 2 returns 2
 */
export const absolute = (reference: string): string => abs(reference);

/**
 * Displays the referenced value if is positive, otherwise displays 0.
 */
export const onlyPositive = (reference: string): string => max(0, reference);

/**
 * Displays the referenced value if is negative, otherwise displays 0.
 */
export const onlyNegative = (reference: string): string =>
  multiply(-1, min(0, reference));
