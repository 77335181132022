import { getUnixTime } from 'date-fns';
import {
  ReconciliationBalanceAccountGroup,
  ReconciliationBalanceAccountRow,
  ReconciliationBalanceKeyFigure,
} from '@agoy/api-sdk-core';
import { isSameOrAfter, isSameOrBefore, parse } from '@agoy/dates';

import { ReconciliationPeriod } from '@agoy/reconciliation';
import {
  RowWithoutClassName,
  Row,
  MovedRows,
  MovedAccountsBalance,
} from '../../RowContext/types';
import { ReconciliationBalanceAccountRowWithMoving } from '../../types';

export type InitialRow =
  | ReconciliationBalanceAccountRow
  | ReconciliationBalanceAccountRowWithMoving
  | ReconciliationBalanceAccountGroup
  | ReconciliationBalanceKeyFigure;

const toAccountRow = (
  row: ReconciliationBalanceAccountRow
): RowWithoutClassName => ({
  type: 'account',
  accountNumber: `${row.number}`,
  id: `${row.number}`,
});

const toKeyFigureRow = (
  row: ReconciliationBalanceKeyFigure
): RowWithoutClassName => ({
  type: 'keyFigure',
  id: row.id,
});

const mapRow = (
  row: InitialRow,
  groupsWithHiddenRow
): RowWithoutClassName[] => {
  if (row.type === 'account') {
    return [toAccountRow(row)];
  }
  if (row.type === 'group') {
    return toGroupRows(row, groupsWithHiddenRow);
  }

  return [toKeyFigureRow(row)];
};

const toGroupRows = (
  row: ReconciliationBalanceAccountGroup,
  groupsWithHiddenRow: string[]
): Row[] => {
  const groupRows = row.rows
    .flatMap((item) => mapRow(item, groupsWithHiddenRow))
    .map((subRow) => ({ ...subRow, className: '' }));
  return [
    {
      type: 'group',
      id: row.id,
      className: '',
      rows: [
        {
          type: 'groupHeader',
          id: row.id,
          className: '',
        },
        ...groupRows,
        {
          type: 'groupSum',
          id: row.id,
          withHiddenRow: groupsWithHiddenRow.includes(row.id),
          groupRows,
          className: '',
        },
      ],
    },
  ];
};

export const extractRows = (
  rows: InitialRow[],
  groupsWithHiddenRow: string[]
): RowWithoutClassName[] =>
  rows.flatMap((row) => {
    switch (row.type) {
      case 'account':
        return toAccountRow(row);
      case 'group':
        return toGroupRows(row, groupsWithHiddenRow);
      case 'key':
        return [
          {
            type: 'keyFigure',
            id: row.id,
          },
        ];
      default:
        return [];
    }
  });

export const createEmptyAccount = (
  number: number,
  movedTo?: string
): ReconciliationBalanceAccountRowWithMoving => ({
  type: 'account',
  number,
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasSpecifications: false,
  ib: 0,
  state: 'not_started',
  ub: 0,
  change: 0,
  isPlaceholder: !!movedTo,
  movedTo,
});

export const createEmptyGroup = (
  id: string
): ReconciliationBalanceAccountGroup => ({
  id,
  type: 'group',
  state: 'not_started',
  hasComment: false,
  hasDocuments: false,
  hasInternalComments: false,
  hasSpecifications: false,
  rows: [],
  sum: {
    ib: 0,
    ub: 0,
    id: `${id}_sum`,
    type: 'key',
    change: 0,
  },
});

const getTimestampDifference = (a: string, b: string) =>
  Math.abs(getUnixTime(parse(a)) - getUnixTime(parse(b)));

export const getClosestDate = (period: string, periods: string[]) => {
  return periods.reduce((prevValue, item) => {
    if (!prevValue) {
      return item;
    }

    return getTimestampDifference(period, item) <
      getTimestampDifference(period, prevValue)
      ? item
      : prevValue;
  }, '');
};

export const injectMovedRows = (
  rows: InitialRow[],
  movedAccountData: MovedAccountsBalance
): InitialRow[] => {
  if (!Object.keys(movedAccountData).length) {
    return rows;
  }
  const res = rows.reduce((prev, row) => {
    if (row.type === 'group') {
      const newAccounts: ReconciliationBalanceAccountRowWithMoving[] = [];

      const internal = injectMovedRows(row.rows, movedAccountData);

      Object.values(movedAccountData[row.id] || {}).forEach((accountRow) => {
        if (
          accountRow &&
          !internal.some(
            (item) =>
              item.type === 'account' && item.number === accountRow.number
          )
        ) {
          newAccounts.push(accountRow);
        }
      });

      return [
        ...prev,
        {
          ...row,
          rows: [...newAccounts, ...internal],
        },
      ];
    }

    if (row.type === 'account') {
      const isMovedAccount = Object.values(movedAccountData).some((item) =>
        Object.keys(item).includes(row.number.toString())
      );

      if (isMovedAccount) {
        return [...prev];
      }
    }

    return [...prev, row];
  }, [] as InitialRow[]);

  return res;
};

const getBalanceValues = (
  subRows: InitialRow[],
  parentGroupId = 'noGroup'
): Record<string, ReconciliationBalanceAccountRowWithMoving> => {
  return subRows.reduce((prev, row) => {
    if (row.type === 'group') {
      return { ...prev, ...getBalanceValues(row.rows, row.id) };
    }
    if (row.type === 'account' && parentGroupId) {
      return { ...prev, [`${parentGroupId}.${row.number}`]: row };
    }
    return prev;
  }, {});
};

const getAccountBalance = (
  balance: Record<string, ReconciliationBalanceAccountRowWithMoving>,
  accountNumber: string,
  movedTo?: string
) => {
  const accountBalance = Object.values(balance).find(
    (value) => value.number.toString() === accountNumber
  );

  if (accountBalance) {
    return { ...accountBalance, movedTo };
  }

  return createEmptyAccount(+accountNumber, movedTo);
};

export const getMovedAccountsBalance = (
  rows: InitialRow[],
  movedRows: MovedRows,
  period: ReconciliationPeriod
): MovedAccountsBalance => {
  const balance = getBalanceValues(rows);

  const result = {};

  Object.keys(movedRows).forEach((accountNumber) => {
    const periods = Object.keys(movedRows[accountNumber]).sort((a, b) =>
      isSameOrAfter(parse(a), parse(b)) ? -1 : 1
    );

    const closestPeriod = getClosestDate(
      period.type === 'yearEnd' ? period.end : period.start,
      periods
    );

    if (
      closestPeriod &&
      (period.type === 'yearEnd' ||
        isSameOrBefore(parse(closestPeriod), parse(period.start)))
    ) {
      const { toGroupId } = movedRows[accountNumber][closestPeriod];

      result[toGroupId] = {
        ...result[toGroupId],
        [accountNumber]: getAccountBalance(balance, accountNumber, toGroupId),
      };
    }
  });

  return result;
};

export const getMovedAccounts = (
  movedRows: MovedRows,
  periodStart: string
): { [accountNumber: string]: string } => {
  const result = {};

  Object.keys(movedRows).forEach((accountNumber) => {
    const periods = Object.keys(movedRows[accountNumber]).sort((a, b) =>
      isSameOrAfter(parse(a), parse(b)) ? -1 : 1
    );

    const closestPeriod = getClosestDate(periodStart, periods);

    if (closestPeriod) {
      const { toGroupId } = movedRows[accountNumber][closestPeriod];

      result[accountNumber] = toGroupId;
    }
  });

  return result;
};

export const checkBalanceValue = (value: number) => {
  return Number.isNaN(value) ? null : value;
};
