import { Subscription } from 'rxjs';
import {
  AnnualReport,
  AnnualReportChanges,
  AnnualReportType,
  ConfiguredFinancialYear,
  DocumentConfiguration,
  operations as annualReportOperations,
  AnnualReportDataService,
  getContentDefinition,
  ContentDefinitionVariants,
  IncomeStatementType,
  AnnualReportStructure,
} from '@agoy/annual-report-document';
import {
  Cell,
  operations,
  AgoyTableColumn,
  Source,
  State,
} from '@agoy/document';
import AnnualReportViewService from './AnnualReportViewService';

type DocumentAndChanges = {
  document: AnnualReport;
  changes: AnnualReportChanges;
};

type OperationResult = DocumentAndChanges | false | string;

class AnnualReportReactiveViewService implements AnnualReportViewService {
  reportDataService: AnnualReportDataService;

  changesSub: Subscription;

  changes?: AnnualReportChanges;

  reportSub: Subscription;

  report?: AnnualReport;

  documentConfiguration?: DocumentConfiguration;

  documentConfigurationSub: Subscription;

  variant: ContentDefinitionVariants;

  userId?: string;

  constructor(
    dataService: AnnualReportDataService,
    variant: ContentDefinitionVariants,
    userId?: string
  ) {
    this.reportDataService = dataService;
    this.changesSub = dataService.changes.subscribe((value) => {
      this.changes = value;
    });
    this.reportSub = dataService.latestReport.subscribe((value) => {
      this.report = value;
    });
    this.documentConfigurationSub = dataService.documentConfiguration.subscribe(
      (value) => {
        this.documentConfiguration = value;
      }
    );

    this.variant = variant;
    this.userId = userId;
  }

  async initialize(): Promise<void> {
    // No initialization needed
  }

  dispose(): void {
    this.changesSub.unsubscribe();
    this.reportSub.unsubscribe();
  }

  getChanges(): AnnualReportChanges {
    return this.changes || {};
  }

  async addRow(
    rowId: string,
    newRowId?: string,
    cellParameters?: Record<string, string>,
    copyId?: string,
    sortKey?: number
  ): Promise<void> {
    const content = this.getContent();
    const state = this.getCurrentState();

    const tableRow = operations.addTableRow(
      content,
      state,
      rowId,
      newRowId,
      cellParameters,
      copyId,
      sortKey
    ) as DocumentAndChanges;

    this.update(tableRow);
  }

  async addSection(id: string): Promise<void> {
    const updatedReport = operations.addSection(
      this.getContent(),
      this.getCurrentState(),
      id
    ) as DocumentAndChanges;

    this.update(updatedReport);
  }

  async addTableColumn(
    tableId: string,
    index: number,
    label: string,
    sortKey?: number,
    columnId?: string
  ): Promise<void> {
    const column: AgoyTableColumn = { id: columnId || `@${Date.now()}`, label };
    if (sortKey !== undefined) {
      column.sortKey = sortKey;
    }
    const changes = operations.addTableColumn(
      this.getContent(),
      this.getCurrentState(),
      tableId,
      column,
      index
    ) as DocumentAndChanges;

    this.update(changes);
  }

  async deleteColumn(columnId: string): Promise<void> {
    const documentAndChanges = operations.deleteTableColumn(
      this.getContent(),
      this.getCurrentState(),
      columnId
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async deleteRow(rowId: string): Promise<void> {
    const documentAndChanges = operations.deleteTableRow(
      this.getContent(),
      this.getCurrentState(),
      rowId
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async deleteSection(id: string): Promise<void> {
    const documentAndChanges = operations.deleteSection(
      this.getContent(),
      this.getCurrentState(),
      id
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async moveAccountRow(
    id: string,
    targetId: string,
    columns: 'all' | 'ib' | 'ub' = 'all'
  ): Promise<void> {
    const test = annualReportOperations.moveAccountRow(
      this.getContent(),
      this.getCurrentState(),
      id,
      targetId,
      columns
    );

    this.update(
      test as { document: AnnualReport; changes: AnnualReportChanges }
    );
  }

  async resetContent(id: string): Promise<void> {
    const currentState = this.getCurrentState();
    const result = operations.removeChanges(
      this.getContent(),
      this.getCurrentState(),
      id
    );
    if (typeof result === 'object') {
      if (result.changes !== currentState.changes) {
        this.reportDataService.update(undefined, result.changes);
      }
    }
  }

  async resetField(id: string): Promise<void> {
    const documentAndChanges = operations.resetField(
      this.getContent(),
      this.getCurrentState(),
      id
    ) as DocumentAndChanges;
    this.update(documentAndChanges);
  }

  async updateDocumentConfiguration(
    change: Omit<Partial<DocumentConfiguration>, 'documentType' | 'version'>
  ): Promise<void> {
    if (!this.documentConfiguration) {
      throw new Error('Have not received a document ');
    }
    await this.reportDataService.updateDocumentConfiguration({
      ...this.documentConfiguration,
      ...change,
    });
  }

  async setIncomeStatementType(type: IncomeStatementType): Promise<void> {
    this.updateDocumentConfiguration({
      incomeStatement: type,
    });
  }

  async setAnnualReportType(reportType: AnnualReportType): Promise<void> {
    await this.updateDocumentConfiguration({
      reportType,
    });
  }

  async setAnnualReportTransition(
    reportTransitionK3toK2: boolean
  ): Promise<void> {
    await this.updateDocumentConfiguration({
      reportTransitionK3toK2,
    });
  }

  async setDigitalSubmission(isDigitalSubmission: boolean): Promise<void> {
    await this.updateDocumentConfiguration({
      isDigitalSubmission,
    });
  }

  async setFinancialYears(
    financialYears: ConfiguredFinancialYear[]
  ): Promise<void> {
    await this.updateDocumentConfiguration({
      financialYears,
    });
  }

  async setSectionActive(id: string, active: boolean): Promise<void> {
    const documentAndChanges = operations.setSectionActive(
      this.getContent(),
      this.getCurrentState(),
      id,
      active
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async toggleFieldActive(fieldId: string): Promise<void> {
    const documentAndChanges = operations.toggleFieldActive(
      this.getContent(),
      this.getCurrentState(),
      fieldId
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateFieldActive(fieldId: string, value: boolean): Promise<void> {
    const documentAndChanges = operations.updateFieldActive(
      this.getContent(),
      this.getCurrentState(),
      fieldId,
      value
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async toggleSectionActive(id: string): Promise<void> {
    const documentAndChanges = operations.toggleSectionActive(
      this.getContent(),
      this.getCurrentState(),
      id
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async toggleTableActive(tableId: string): Promise<void> {
    const documentAndChanges = operations.toggleTableActive(
      this.getContent(),
      this.getCurrentState(),
      tableId
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async toggleTableRowActive(rowId: string): Promise<void> {
    const documentAndChanges = operations.toggleTableRowActive(
      this.getContent(),
      this.getCurrentState(),
      rowId
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async toggleTableRowGroupActive(
    rowId: string,
    value?: boolean
  ): Promise<void> {
    const documentAndChanges = operations.toggleTableRowGroupActive(
      this.getContent(),
      this.getCurrentState(),
      rowId,
      value
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateCellValue(
    cellId: string,
    value: string | number | Cell | undefined,
    options: { keepOriginal?: boolean } = {}
  ): Promise<void> {
    switch (typeof value) {
      case 'string':
        this.update(
          operations.updateCellValue(
            this.getContent(),
            this.getCurrentState(),
            cellId,
            {
              type: 'string',
              value,
            },
            options.keepOriginal ?? false
          ) as DocumentAndChanges
        );
        break;
      case 'number':
      case 'undefined':
        this.update(
          operations.updateCellValue(
            this.getContent(),
            this.getCurrentState(),
            cellId,
            {
              type: 'number',
              value,
            },
            options.keepOriginal ?? false
          ) as DocumentAndChanges
        );
        break;
      default:
        this.update(
          operations.updateCellValue(
            this.getContent(),
            this.getCurrentState(),
            cellId,
            value,
            options.keepOriginal ?? false
          ) as DocumentAndChanges
        );
    }
  }

  async updateRowValues(
    rowId: string,
    values: { [key: string]: Cell | string | number | undefined },
    options: { keepOriginal?: boolean } = {}
  ): Promise<void> {
    let result: State<AnnualReportStructure> | boolean | string =
      this.getCurrentState();

    Object.keys(values).forEach((key) => {
      const value = values[key];
      const cellId = `${rowId}.${key}`;

      if (typeof result !== 'object') {
        return;
      }

      switch (typeof value) {
        case 'string':
          result = operations.updateCellValue(
            this.getContent(),
            result,
            cellId,
            {
              type: 'string',
              value,
            },
            options.keepOriginal ?? false
          );
          break;
        case 'number':
        case 'undefined':
          result = operations.updateCellValue(
            this.getContent(),
            result,
            cellId,
            {
              type: 'number',
              value,
            },
            options.keepOriginal ?? false
          );
          break;
        default:
          result = operations.updateCellValue(
            this.getContent(),
            result,
            cellId,
            value,
            options.keepOriginal ?? false
          );
      }
    });

    this.update(result as OperationResult);
  }

  async updateCellReferences(
    cellId: string,
    references: string[]
  ): Promise<void> {
    const documentAndChanges = operations.updateCellReferences(
      this.getContent(),
      this.getCurrentState(),
      cellId,
      references
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateCellReference(cellId: string, reference: string): Promise<void> {
    const documentAndChanges = operations.updateCellReference(
      this.getContent(),
      this.getCurrentState(),
      cellId,
      reference
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateColumnLabel(columnId: string, label: string): Promise<void> {
    const documentAndChanges = operations.updateTableColumnLabel(
      this.getContent(),
      this.getCurrentState(),
      columnId,
      label
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateColumnActive(columnId: string, active: boolean): Promise<void> {
    const documentAndChanges = operations.updateTableColumnActive(
      this.getContent(),
      this.getCurrentState(),
      columnId,
      active
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  /**
   * Updates a field value.
   *
   * Optionally setting the source of the value.
   * The current userId will be added to the source.
   * The current time will be set by this function in updatedAt.
   *
   * @param fieldId
   * @param value
   * @param source
   */
  async updateField(
    fieldId: string,
    value: string | number | undefined | boolean,
    source?: Cell['source']
  ): Promise<void> {
    let patchedSource = source;
    if (patchedSource) {
      if (!patchedSource.userId) {
        // Default to the current userId
        patchedSource = { ...patchedSource, userId: this.userId };
      }
      if (!patchedSource?.updatedAt) {
        // Default to now
        patchedSource = { ...patchedSource, updatedAt: Date.now() };
      }
    }
    const documentAndChanges = operations.updateField(
      this.getContent(),
      this.getCurrentState(),
      fieldId,
      value,
      patchedSource
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateMessageField(fieldId: string, message: string): Promise<void> {
    const documentAndChanges = operations.updateMessageField(
      this.getContent(),
      this.getCurrentState(),
      fieldId,
      message
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateColumnSortKey(
    columnsWithSortKey: {
      id: string;
      sortKey: number;
    }[]
  ): Promise<void> {
    const documentAndChanges = operations.updateTableColumnSortKey(
      this.getContent(),
      this.getCurrentState(),
      columnsWithSortKey
    ) as DocumentAndChanges;

    this.update(documentAndChanges);
  }

  async updateRows(
    rows: Parameters<typeof operations.updateTableRows>[2]
  ): Promise<void> {
    const content = this.getContent();
    const state = this.getCurrentState();
    const updatedRows = operations.updateTableRows(
      content,
      state,
      rows
    ) as DocumentAndChanges;

    this.update(updatedRows);
  }

  async updateTableSource(tableId: string, source: Source): Promise<void> {
    const content = this.getContent();
    const state = this.getCurrentState();

    const updatedTableSource = operations.updateTableSource(
      content,
      state,
      tableId,
      source
    ) as OperationResult;

    this.update(updatedTableSource);
  }

  async updateDocumentType(changes: ContentDefinitionVariants): Promise<void> {
    if (!this.documentConfiguration) {
      throw new Error('Have not received a document ');
    }
    await this.reportDataService.updateDocumentConfiguration({
      ...this.documentConfiguration,
      ...changes,
    });
  }

  async resetTableRow(rowId: string): Promise<void> {
    const content = this.getContent();
    const state = this.getCurrentState();

    const result = operations.resetTableRow(
      content,
      state,
      rowId
    ) as DocumentAndChanges;

    this.update(result);
  }

  private update(result: OperationResult): void {
    if (result) {
      if (typeof result === 'string') {
        // eslint-disable-next-line no-console
        console.warn(result);
      } else {
        this.reportDataService.update(result.document, result.changes);
      }
    }
  }

  private getCurrentState() {
    if (this.report && this.changes) {
      return {
        document: this.report,
        changes: this.changes,
      };
    }

    throw new Error('Report or changes not initialized yet');
  }

  getContent() {
    return getContentDefinition(
      this.variant,
      this.documentConfiguration?.reportType ?? 'k3'
    );
  }
}

export default AnnualReportReactiveViewService;
