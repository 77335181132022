import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { last } from 'lodash';
import { map } from 'rxjs';

import {
  ReconciliationBalanceAccountGroup,
  ReconciliationBalanceAccountRow,
  ReconciliationBalanceKeyFigure,
} from '@agoy/api-sdk-core';
import useClientDataLayer from 'data/client/useClientDataLayer';
import { useSelector } from 'redux/reducers';
import {
  getKeyFigureById,
  createContext,
  getAccounts,
  ReconciliationPeriod,
} from '@agoy/reconciliation';
import { getPeriodStatusReconciliation } from '_reconciliation/redux/accounting-view/selectors';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';

import RowContext from '../../RowContext';
import HiddenRowsContext from '../../RowContext/HiddenRowsContext';
import MoveAccountsContext from '../../RowContext/MoveAccountsContext';
import { AccountRow, GroupSumRow } from '../../RowContext/types';
import { getHiddenRowGroups } from '../../RowContext/utils';
import RenderColumnContent from '../RenderColumnContent';
import { AccountBalance, SizeType } from '../../types';
import PeriodHeader from './Cells/PeriodHeader';
import GroupSumCell from './Cells/GroupSumCell';
import AccountCell from './Cells/AccountCell';
import { getColumnWidth } from './PeriodColumn';
import { periodTypeToRequestPeriod } from '../../utils';
import KeyFigureCell from './Cells/KeyFigureCell';
import {
  InitialRow,
  checkBalanceValue,
  createEmptyAccount,
  createEmptyGroup,
  extractRows,
  getMovedAccounts,
  getMovedAccountsBalance,
  injectMovedRows,
} from './utils';

export type PeriodColumnContentProps = {
  className?: string;
  clientId: string;
  period: ReconciliationPeriod;
  size: SizeType;
  expanded?: boolean;
  notActive?: boolean;
};

interface InternalState {
  balances: AccountBalance | undefined;
  groups: { [groupId: string]: ReconciliationBalanceAccountGroup } | undefined;
  keyFigures: Record<string, ReconciliationBalanceKeyFigure> | undefined;
}

const Container = styled.div<{ columnSize: number }>`
  position: relative;
  padding-left: 5px;
  width: ${({ columnSize }) => columnSize}px;

  & .groupHeader {
    border-bottom: 2px solid #eeeeee;
    border-left: 2px solid #eeeeee;
    border-right: 2px solid #eeeeee;
  }
`;

const Content = styled.div<{ showCollapsedColumn: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ showCollapsedColumn }) =>
    showCollapsedColumn ? 'padding-left: 9px' : ''}
`;

const CollapsedColumn = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 5px;
  top: 0;
  width: 9px;
  height: 100%;
  z-index: 0;
`;

const VerticalLine = styled.div<{ index: number }>`
  width: 2px;
  background-color: #dde1e0;
  margin-top: ${({ index }) => index * 3}px;
  margin-right: 1px;
`;

const isMovingAccountEnabled = activeFeatureFlags.get(
  'feature_moveAccountsBetweenGroups'
);

const PeriodColumnContent = ({
  className,
  clientId,
  period,
  size,
  notActive = false,
  expanded = false,
}: PeriodColumnContentProps) => {
  const service = useClientDataLayer(clientId);
  const { rows, addRows, removeRows } = useContext(RowContext);
  const { localMovedAccounts, movedAccountsByPeriods } =
    useContext(MoveAccountsContext);
  const { hiddenRows } = useContext(HiddenRowsContext);

  const lastSubPeriod = last(period.periods);

  const type = useSelector((state) => state.customers[clientId].type);
  const isPeriodLocked = useSelector(
    getPeriodStatusReconciliation(
      lastSubPeriod || null,
      (state) => state.status === 'LOCKED'
    )
  );

  const [{ keyFigures, balances, groups }, setState] = useState<InternalState>({
    keyFigures: undefined,
    balances: undefined,
    groups: undefined,
  });

  const [initialRows, setInitialRows] = useState<InitialRow[]>([]);

  useEffect(() => {
    const sub = service.reconciliation
      .getAccounts(
        period.start,
        period.end,
        periodTypeToRequestPeriod(period.type),
        period.type === 'yearEnd'
      )
      .pipe(
        map((result) => {
          if (result.ok && result.val) {
            setInitialRows(result.val.rows);
          }
        })
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [service, period]);

  const movedAccountsBalance = useMemo(() => {
    return getMovedAccountsBalance(initialRows, localMovedAccounts, period);
  }, [initialRows, localMovedAccounts, period]);

  const allAccounts = useMemo(() => {
    const getAllAccounts = (
      subRows: InitialRow[]
    ): ReconciliationBalanceAccountRow[] =>
      subRows.reduce((prev, row) => {
        if (row.type === 'group') {
          return [...prev, ...getAllAccounts(row.rows)];
        }

        if (row.type === 'account') {
          return [...prev, row];
        }

        return prev;
      }, [] as ReconciliationBalanceAccountRow[]);

    return getAllAccounts(initialRows);
  }, [initialRows]);

  const recalculateKeyFigures = useCallback(
    (keyFiguresValue: Record<string, ReconciliationBalanceKeyFigure>) => {
      const movedAccountsData = getMovedAccounts(
        localMovedAccounts,
        period.start
      );
      const accounts = getAccounts(
        allAccounts,
        movedAccountsData,
        createEmptyAccount
      );

      const updatedKeyFigures = Object.values(keyFiguresValue).reduce(
        (prev, item) => {
          const context = createContext(
            {
              rows: [],
              start: period.start,
              end: period.end,
              startFinancialYear: period.financialYears[0],
              endFinancialYear:
                period.financialYears[period.financialYears.length - 1],
            },
            type,
            movedAccountsData
          );

          const recalculate = getKeyFigureById(item.id);
          const calculated = recalculate(context, accounts);

          return {
            ...prev,
            [item.id]: {
              ...calculated,
              ib: checkBalanceValue(calculated.ib),
              ub: checkBalanceValue(calculated.ub),
              change: checkBalanceValue(calculated.change),
            },
          };
        },
        {} as Record<string, ReconciliationBalanceKeyFigure>
      );

      return updatedKeyFigures;
    },
    [allAccounts, localMovedAccounts, period, type]
  );

  const getBalanceValues = useCallback(
    (balanceRows: InitialRow[]) => {
      const newAccounts: typeof balances = {};
      const newGroups: typeof groups = {};
      const newKeyFigures: typeof keyFigures = {};

      const getValues = (
        subRows: InitialRow[],
        parentGroupId = 'noGroup'
      ): {
        change: number;
        ib: number;
        ub: number;
      } => {
        const resultSum = subRows.reduce(
          (prev, row) => {
            if (row.type === 'group') {
              const groupSum = getValues(row.rows, row.id);
              const updatedSumRow = { ...row.sum, ...groupSum };

              newGroups[row.id] = {
                ...row,
                sum: updatedSumRow,
              };
              newAccounts[row.id] = updatedSumRow;

              return {
                change: groupSum.change + prev.change,
                ib: groupSum.ib + prev.ib,
                ub: groupSum.ub + prev.ub,
              };
            }

            if (row.type === 'account' && parentGroupId) {
              const key = `${parentGroupId}.${row.number}`;

              newAccounts[key] = row;
              return {
                change: row.change + prev.change,
                ib: row.ib + prev.ib,
                ub: row.ub + prev.ub,
              };
            }

            if (row.type === 'key') {
              newKeyFigures[row.id] = row;
            }

            return prev;
          },
          { change: 0, ib: 0, ub: 0 }
        );

        return resultSum;
      };

      getValues(balanceRows);

      const recalculatedKeyFigures = recalculateKeyFigures(newKeyFigures);

      return {
        balances: newAccounts,
        groups: newGroups,
        keyFigures: isMovingAccountEnabled
          ? recalculatedKeyFigures
          : newKeyFigures,
      };
    },
    [recalculateKeyFigures]
  );

  useEffect(() => {
    const injectedRows = injectMovedRows(initialRows, movedAccountsBalance);

    if (!notActive && period.type !== 'yearEnd') {
      addRows(
        `period-${period.start}`,
        extractRows(injectedRows, getHiddenRowGroups(type))
      );
    }

    setState(getBalanceValues(injectedRows));
  }, [
    addRows,
    getBalanceValues,
    initialRows,
    movedAccountsBalance,
    notActive,
    period,
    type,
  ]);

  useEffect(() => {
    return () => {
      if (!notActive) {
        removeRows(`period-${period.start}`);
      }
    };
  }, [notActive, period.start, removeRows]);

  const checkActiveGroup = useCallback(
    (groupId: string) => {
      const hiddenRow = hiddenRows[groupId]?.period;

      return hiddenRow?.start === period.start && hiddenRow?.end === period.end;
    },
    [hiddenRows, period]
  );

  const accountRenderer = useCallback(
    (r: AccountRow, groupId: string, inHiddenGroupRow: boolean) => {
      if (balances && !balances[`${groupId}.${r.accountNumber}`]) {
        balances[`${groupId}.${r.accountNumber}`] = createEmptyAccount(
          parseInt(r.accountNumber, 10),
          movedAccountsByPeriods[r.accountNumber]?.[period.start]
        );
      }
      const row = balances?.[`${groupId}.${r.accountNumber}`];
      const balanceRow = row?.type === 'account' ? row : undefined;

      return (
        <AccountCell
          accountBalance={balanceRow}
          period={period}
          rowId={r.id}
          inHiddenGroupRow={inHiddenGroupRow}
          groupId={groupId}
          companyType={type}
          isPeriodLocked={!!isPeriodLocked}
        />
      );
    },
    [balances, isPeriodLocked, movedAccountsByPeriods, period, type]
  );

  const groupSumRenderer = useCallback(
    (r: GroupSumRow) => {
      if (groups && !groups[r.id]) {
        // The groups are loaded but this one is missing
        groups[r.id] = createEmptyGroup(r.id);
      }

      return (
        <GroupSumCell
          groupId={r.id}
          row={groups?.[r.id]}
          period={period}
          groupRows={r.groupRows}
          withHiddenRow={r.withHiddenRow}
          balances={balances}
        />
      );
    },
    [groups, period, balances]
  );

  const keyFigureRenderer = useCallback(
    (r) => {
      return <KeyFigureCell row={keyFigures?.[r.id]} />;
    },
    [keyFigures]
  );

  const headerRenderer = useCallback(
    () => (
      <PeriodHeader
        clientId={clientId}
        period={period}
        notActive={notActive}
        balances={balances}
      />
    ),
    [clientId, notActive, period, balances]
  );

  const showCollapsedColumn =
    (period.type === 'quarter' || period.type === 'financialYear') && !expanded;

  return (
    <Container
      className={className}
      columnSize={getColumnWidth(size, period.type, expanded)}
    >
      {showCollapsedColumn && (
        <CollapsedColumn>
          <VerticalLine index={3} />
          <VerticalLine index={2} />
          <VerticalLine index={1} />
        </CollapsedColumn>
      )}

      <Content showCollapsedColumn={showCollapsedColumn}>
        {rows.map((row) => (
          <RenderColumnContent
            clientId={clientId}
            periodStart={period.start}
            row={row}
            key={`${row.type}_${row.id}`}
            header={headerRenderer}
            account={accountRenderer}
            groupSum={groupSumRenderer}
            keyFigure={keyFigureRenderer}
            checkActiveGroup={checkActiveGroup}
          />
        ))}
      </Content>
    </Container>
  );
};

export default PeriodColumnContent;
