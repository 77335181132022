import React, { Fragment } from 'react';
import {
  isTableType,
  stringValue,
  filterRows,
  numberValue,
  booleanValue,
} from '@agoy/document';
import {
  getFieldType,
  isAnyGroupActive,
  isNoteContainer,
  removeDuplicatedIXBRLFromFirstColumn,
} from './helpers';
import HierarchicalPrintTable from '../../../UI/HierarchicalPrintTable/v2/HierarchicalPrintTable';
import { Note, NoteContainer, NoteContent, NoteProps } from './types';
import { IXElement } from '../../../ARKeyToIX';
import Typography from '../../../UI/Typography/Typography';
import Spacer from '../../../UI/helpers/Spacer';
import splitTableByColumns from '../../../utils/splitTableByColumns';
import { useIntl } from 'react-intl';

interface NoteContentProps {
  note: Note | NoteContainer | NoteContent;
  noteId: string;
  hasPreviousYear?: boolean;
  commentForTableGroup?: boolean;
}

const NoteContentRender = ({
  note,
  noteId,
  hasPreviousYear,
  commentForTableGroup,
}: NoteContentProps): React.ReactElement | null => {
  const fields = Object.keys(note).filter(
    (key) =>
      ![
        'name',
        'section',
        'number',
        'group',
        'validForReportType',
        'type',
      ].includes(key)
  );

  const content = fields.map((fieldKey) => {
    const field = note[fieldKey];
    const type = getFieldType(field);

    if (!type) return null;

    const key = `${noteId}.${fieldKey}`;

    if (type === 'group') {
      return (
        <NoteContentRender
          key={key}
          note={note[fieldKey]}
          noteId={`${noteId}.${fieldKey}`}
          hasPreviousYear={hasPreviousYear}
          commentForTableGroup={
            fields.some((key) => key === 'table') &&
            fieldKey.includes('Kommentar')
          }
        />
      );
    }

    if (!note.active) return null;

    if (isTableType(field)) {
      const columns = field.columns.filter(
        ({ id }) => id !== 'previousYear' || hasPreviousYear
      );

      const splitTables = splitTableByColumns(
        { ...field, columns },
        (_, index) => index === 0
      );

      // We need to remove ixbrl data from the first column of second and third table
      // otherwise it will be duplicated in the print and the note will not be correctly validated
      const filteredData = removeDuplicatedIXBRLFromFirstColumn(splitTables);

      const caption = isNoteContainer(note) ? stringValue(note.title) : '';

      return filteredData
        .filter((table) => booleanValue(table.active))
        .map((table, index) => (
          <React.Fragment key={key}>
            <Spacer size={2} />

            <HierarchicalPrintTable
              table={filterRows(table, undefined, false)}
              tableId={`notes.${noteId}.${fieldKey}`}
              caption={index === 0 ? caption : ''}
            />
            <Spacer size={1} />
          </React.Fragment>
        ));
    }

    const value = stringValue(field);
    const isTitle = fieldKey === 'title';
    const isNote1 = noteId.startsWith('note1.data');

    if (isTitle) {
      // There is a special case for Note1 where we don't want to print the title for comments
      const hideCommentTitleInNote1 = isNote1 && value?.includes('Kommentar');

      // In Note1 we don't want to print the title for Avskrivningsprinciper (only table in Note1)
      const hideDeprecationTitle = isNote1 && value === 'Avskrivningsprinciper';

      // Only print titles for Note1
      if (!isNote1 || hideCommentTitleInNote1 || hideDeprecationTitle)
        return null;

      return (
        <Typography key={key} variant="h4">
          {value}
        </Typography>
      );
    }

    // add standardRubrik on comments
    const isComment = field.ixbrl?.standardRubrik.includes('Kommentar');

    if (!value?.trim()) return false;

    const valueByNewLine = value.split('\n');
    const splitId = noteId.split('.');
    const fieldId = splitId[splitId.length - 1];

    return (
      <React.Fragment key={key}>
        {isComment && !commentForTableGroup && (
          <Typography variant={fieldId.includes('Not') ? 'h4' : 'h5'}>
            {field.ixbrl.standardRubrik}
          </Typography>
        )}
        <IXElement arPart={field}>
          <Typography variant="body1" as="div">
            {valueByNewLine.map((line) => (
              <Typography variant="body1" margin="none">
                {line}
              </Typography>
            ))}
          </Typography>
        </IXElement>
      </React.Fragment>
    );
  });

  return <Fragment key={noteId}>{content}</Fragment>;
};

/**
 * This component renders notes that consist of name, fields and tables.
 *
 */
const NoteComponent = ({ noteId, note, hasPreviousYear }: NoteProps) => {
  const { formatMessage } = useIntl();
  const isCustom = noteId.includes('custom');

  const isGroupInactive = !isCustom && !isAnyGroupActive(note.data);
  const isCustomInactive = isCustom && !booleanValue(note.active);

  if (isGroupInactive || isCustomInactive) return null;

  return (
    <div>
      <Typography variant="h3">
        {formatMessage(
          { id: 'annualReport.notes.title' },
          {
            number: numberValue(note.number),
            title: stringValue(note.name),
          }
        )}
      </Typography>

      <NoteContentRender
        note={isCustom ? note : note.data}
        noteId={isCustom ? noteId : `${noteId}.data`}
        hasPreviousYear={hasPreviousYear}
      />
    </div>
  );
};

export default NoteComponent;
