import React from 'react';
import styled from '@emotion/styled';
import { CoverLetterFields } from '@agoy/tax-declaration-document';
import Spacer from '@agoy/annual-report-view/src/UI/helpers/Spacer';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';

import {
  LogoOrName,
  SigningOFIncomeDeclaration,
  ToSkatteverket,
  TaxTable,
} from './Components';
import { Input } from './Input';

const Paper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px
    ${({ theme }) => theme.spacing(6)}px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 900px;
  aspect-ratio: 1 / 1.41; /* A4 aspect ratio */

  @media print {
    box-shadow: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 80px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const TitleSection = styled.div``;

const InputGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

type CoverLetterProps = {
  fields: CoverLetterFields;
  logo: string | undefined;
  orgName: string | undefined;
};

const CoverLetter = ({ fields, logo, orgName }: CoverLetterProps) => {
  return (
    <Paper>
      <HeaderContainer>
        <TitleSection>
          <Typography variant="h1" margin="none">
            Följebrev
          </Typography>
          <Typography variant="h2" margin="none">
            Till inkomstdeklarationen
          </Typography>

          <Spacer size={2} />

          <Input field={fields.name} label="Namn" keyValue="name" />
          <Spacer size={1} />
          <InputGroup>
            <Input
              field={fields.personalNumber}
              label="Person-/Organisationsnummer"
              keyValue="personalNumber"
            />
            <Input
              field={fields.taxYear}
              label="Beskattningsår"
              keyValue="taxYear"
            />
          </InputGroup>
        </TitleSection>
        <LogoSection>
          <LogoOrName logo={logo} orgName={orgName} />
        </LogoSection>
      </HeaderContainer>
      <BodyContainer>
        <Spacer size={3} />
        <Input field={fields.missionTitle} keyValue="missionTitle" title />
        <Spacer size={0.5} />
        <Input field={fields.missionText} keyValue="missionText" multiline />
        <Spacer size={3} />
        <Input
          field={fields.responsibilityTitle}
          keyValue="responsibilityTitle"
          title
        />
        <Spacer size={0.5} />
        <Input
          field={fields.responsibilityText}
          keyValue="responsibilityText"
          multiline
        />
        <Spacer size={1} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <SigningOFIncomeDeclaration
            signatureTitle={fields.signatureTitle}
            signatureOfIncomeDeclaration={fields.signatureOfIncomeDeclaration}
            signatureText={fields.signatureText}
          />
          <ToSkatteverket
            toSkatteverketTitle={fields.toSkatteverketTitle}
            checkboxes={[
              fields.toSkatteverketCheckbox1,
              fields.toSkatteverketCheckbox2,
              fields.toSkatteverketCheckbox3,
              fields.toSkatteverketCheckbox4,
            ]}
            toSkatteverketText={fields.toSkatteverketText}
          />
        </div>
        <Spacer size={3} />
        <Input
          field={fields.taxCalculationTitle}
          keyValue="taxCalculationTitle"
          title
        />
        <Spacer size={0.5} />
        <Input
          field={fields.taxCalculationText}
          keyValue="taxCalculationText"
          multiline
        />
        <TaxTable
          estimatedFinalTax={fields.estimatedFinalTax}
          estimatedTaxRefund={fields.estimatedTaxRefund}
          fTax={fields.fTax}
        />

        <Spacer size={3} />
        <Input
          field={fields.paymentToSkatteverketTitle}
          keyValue="paymentToSkatteverketTitle"
          title
        />
        <Spacer size={0.5} />
        <Input
          field={fields.paymentToSkatteverketText}
          keyValue="paymentToSkatteverketText"
          multiline
        />
        <Spacer size={3} />
        <Input field={fields.commentTitle} keyValue="commentTitle" title />
        <Spacer size={0.5} />
        <Input field={fields.commentText} keyValue="commentText" multiline />
      </BodyContainer>
    </Paper>
  );
};

export default CoverLetter;
