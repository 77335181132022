import { value } from '@agoy/document';
import { TaxDeclarationFormDefinition } from '../../types';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configCoverLetter = (form: TaxDeclarationFormDefinition) => {
  const CoverLetter = mapFormId(form, 'CL');
  const initial = {
    CL: {
      partType: 'coverLetter' as const,
      id: CoverLetter,
      fields: {
        name: value(''),
        personalNumber: value(''),
        taxYear: value(''),
        missionTitle: value('Uppdrag'),
        missionText:
          value(`[Accounting Firm] skickar härmed det upprättade förslaget till inkomstdeklaration [för dig att gå igenom och underteckna.
Vänligen skicka tillbaka den undertecknade deklarationen till oss när du har gått igenom den och är nöjd med innehållet.]`),
        responsibilityTitle: value('Ansvar'),
        responsibilityText: value(
          'Vi vill påminna om att deklarationen är ert ansvar och att du bör granska alla uppgifter i deklarationen noga. Om det finns några frågor eller oklarheter, tveka inte att kontakta oss för att diskutera eventuella ändringar eller korrigeringar.'
        ),

        signatureTitle: value('Underskrift av inkomstdeklaration'),
        signatureOfIncomeDeclaration: value('1'),
        signatureText: value(
          'Byrån signerar efter inkommen deklarationsblankett.'
        ),

        toSkatteverketTitle: value('Inlämning till Skatteverket'),
        toSkatteverketCheckbox1: value(false),
        toSkatteverketCheckbox2: value(false),
        toSkatteverketCheckbox3: value(false),
        toSkatteverketCheckbox4: value(false),
        toSkatteverketText: value(''),

        taxCalculationTitle: value('Skatteberäkning'),
        taxCalculationText: value(
          'Skatteberäkningen som bifogas baseras på uppgifterna i inkomstdeklarationen.'
        ),
        estimatedFinalTax: value(0),
        estimatedTaxRefund: value(0),
        fTax: value(0),

        paymentToSkatteverketTitle: value('Inbetalning till Skatteverket'),
        paymentToSkatteverketText: value(
          'För att undvika räntekostnader rekommenderar vi att du gör en fyllnadsinbetalning till Skatteverket på följande belopp, senast slutdatum.'
        ),
        commentTitle: value('Tack för ert förtroende!'),
        commentText: value(
          `Om du har några frågor eller behöver mer information, tveka inte att kontakta oss.\nDetaljer om uppdraget finns i vårt uppdragsavtal med bilagda allmänna villkor.\n\nVänliga hälsningar,\n[Name Consultant] [Accounting Firm]`
        ),
      },
      sru: {},
    },
  };

  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configCoverLetter;
