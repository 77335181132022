export const hiddenRowGroups = [
  '1000_1099',
  '1100_1299',
  '1300_1399',
  '1400_1499',
  '1500_1799',
  '1800_1899',
  '1900_1999',
  '2000_2089',
  '2000_2089',
  '2090_2099',
  '2100_2199',
  '2200_2299',
  '2300_2399',
  '2400_2999',
  '3000_3799',
  '3800_3999',
  '4000_4999',
  '5000_6999',
  '7000_7699',
  '7700_7899',
  '7900_7999',
  '8000_8799',
  '8800_8999',
];

export const sharedCompanyHiddenRowGroups = [
  '0_999',
  '1000_1099',
  '1100_1199',
  '1200_1299',
  '1300_1399',
  '1400_1499',
  '1500_1599',
  '1600_1699',
  '1700_1799',
  '1800_1899',
  '1900_1999',
  '2000_2099',
  '2100_2199',
  '2200_2299',
  '2300_2399',
  '2400_2499',
  '2500_2599',
  '2600_2699',
  '2700_2799',
  '2800_2899',
  '2900_2999',
  '3000_3899',
  '3900_3999',
  '4000_4999',
  '5000_6999',
  '7000_7699',
  '7700_7899',
  '7900_7999',
  '8000_8799',
  '8800_8899',
  '8900_8999',
];

export const getHiddenRowGroups = (type: string) => {
  return type === 'shares' ? sharedCompanyHiddenRowGroups : hiddenRowGroups;
};

export const getOriginalGroup = (
  accountNumber: string,
  companyType: string
) => {
  return getHiddenRowGroups(companyType).find((group) => {
    const [start, end] = group.split('_').map((item) => parseInt(item, 10));
    const number = parseInt(accountNumber, 10);
    return number >= start && end >= number;
  });
};
