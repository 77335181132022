import {
  id,
  sum,
  or,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
} from '@agoy/document';
import { BalanceSheet } from '../../common/types/balanceSheet';
import { addBalanceSheetGroup, addPost } from '../../common/utils/accountsUtil';
import {
  table,
  RowsBuilder,
  getLinkToNote,
  SectionRow,
} from '../../common/utils/util';
import { getWarningCell } from '../types';

type AccountTypes =
  | 'intangibleAssets'
  | 'inventory'
  | 'restrictedEquity'
  | 'liabilities'
  | 'unassignedAccounts';

export type IndividualCompanyBalanceSheetStructure = {
  [key in AccountTypes]: SectionRow[];
};

export const individualCompanyBalanceSheetAccounts: IndividualCompanyBalanceSheetStructure =
  {
    // Immateriella anläggningstillgångar
    intangibleAssets: [
      {
        row: '1',
        name: 'Immateriella anläggningstillgångar',
        accounts: [[1000, 1099]],
      },
      {
        row: '2',
        name: 'Byggnader och markanläggningar',
        accounts: [
          [1100, 1129],
          [1150, 1199],
        ],
      },
      {
        row: '3',
        name: 'Mark och andra tillgångar som inte får skrivas av',
        accounts: [[1130, 1149]],
      },
      { row: '4', name: 'Maskiner och inventarier', accounts: [[1200, 1289]] },
      {
        row: '5',
        name: 'Övriga anläggningstillgångar',
        accounts: [[1290, 1399]],
      },
    ],
    inventory: [
      {
        row: '1',
        name: 'Varulager',
        accounts: [[1400, 1499]],
      },
      {
        row: '2',
        name: 'Kundfordringar',
        accounts: [[1500, 1599]],
      },
      {
        row: '3',
        name: 'Övriga fordringar',
        accounts: [[1600, 1899]],
      },
      // AGOY-4125 Moved the row, but kept ID to keep the data saved here
      {
        row: '4',
        name: 'Kassa och bank',
        accounts: [[1900, 1990]],
      },
    ],
    restrictedEquity: [
      {
        row: '1',
        name: 'Eget kapital',
        accounts: [[2000, 2059]],
      },
    ],
    liabilities: [
      {
        row: '1',
        name: 'Låneskulder',
        accounts: [[2300, 2439]],
      },
      {
        row: '2',
        name: 'Skatteskulder',
        accounts: [[2500, 2599]],
      },
      {
        row: '3',
        name: 'Leverantörsskulder',
        accounts: [[2440, 2499]],
      },
      {
        row: '4',
        name: 'Övriga skulder',
        accounts: [
          [2600, 2799],
          [2800, 2999],
        ],
      },
    ],
    unassignedAccounts: [
      {
        row: '1',
        name: 'Ej tilldelade konton',
        accounts: [
          [1991, 1999],
          [2060, 2099],
          [2100, 2199],
          [2200, 2299],
        ],
      },
    ],
  };

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  switchSign: boolean,
  rows: RowsBuilder,
  previousPeriod: string,
  structureKey: keyof IndividualCompanyBalanceSheetStructure
) => {
  const add = addPost(accounts, switchSign, rows, previousPeriod);
  individualCompanyBalanceSheetAccounts[structureKey].map((section) => {
    const link = getLinkToNote(section, accounts);

    add(section.row, section.name, section.accounts, link);
  });
};

const addFixedAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'intangibleAssets',
    'Anläggningstillgångar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'intangibleAssets'),
    getWarningCell,
    'Summa anläggningstillgångar'
  );
};

const addCurrentAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'inventory',
    'Omsättningstillgångar',
    (rows) => attachRows(accounts, false, rows, previousPeriod, 'inventory'),
    getWarningCell,
    'Summa omsättningstillgångar'
  );
};

const addRestrictedEquity = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'restrictedEquity',
    'Eget kapital',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'restrictedEquity'),
    getWarningCell,
    ''
  );
};

const addLiabilities = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'liabilities',
    'Skulder',
    (rows) => attachRows(accounts, true, rows, previousPeriod, 'liabilities'),
    getWarningCell,
    'Summa skulder'
  );
};

const addUnassignedAccounts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'unassignedAccounts',
    'Ej tilldelade',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'unassignedAccounts'),
    getWarningCell,
    'Ej tilldelade total'
  );
};

export const balanceSheetConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  previousYearKey: string
): BalanceSheet => ({
  active: undefined,
  section: {
    active: undefined,
    assets: table(
      'balanceSheet.section.assets',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning'
    )
      .addRows((rows) =>
        rows
          .addRowWithType('assets', 'header', value('Tillgångar'))
          .addSubRows((rows) => {
            rows
              .addRowWithType('fixedAndCurrentAssets', 'header')
              // Anläggningstillgångar
              .addSubRows((rows) => {
                addFixedAssets(accounts, rows, previousYearKey);
                return rows.build();
              });

            rows.addSubRows((rows) => {
              addCurrentAssets(accounts, rows, previousYearKey);
              return rows.build();
            });
            return rows.build();
          })
          .addRowWithType(
            'sumAssets',
            'sum',
            value('Summa tillgångar'),
            refs(),
            ref(
              or(
                sum(
                  id(
                    `${rows.getBaseId()}.assets.fixedAndCurrentAssets.intangibleAssetsSum.year`
                  ),
                  id(
                    `${rows.getBaseId()}.assets.fixedAndCurrentAssets.inventorySum.year`
                  )
                ),
                0
              )
            ),
            ref(
              or(
                sum(
                  id(
                    `${rows.getBaseId()}.assets.fixedAndCurrentAssets.intangibleAssetsSum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.assets.fixedAndCurrentAssets.inventorySum.previousYear`
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),
    equityAndLiabilities: table(
      'balanceSheet.section.equityAndLiabilities',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning'
    )
      .addRows((rows) =>
        rows
          .addRowWithType(
            'equityAndLiabilities',
            'header',
            value('Eget kapital och skulder')
          )
          .addSubRows((rows) => {
            rows.addRowWithType('equity', 'header').addSubRows((rows) => {
              addRestrictedEquity(accounts, rows, previousYearKey);
              return rows.build();
            });

            rows.addRowWithType('liabilities', 'header').addSubRows((rows) => {
              addLiabilities(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Ej tilldelade AKA unassigned accounts
            rows
              .addRowWithType('unassignedAccounts', 'header')
              .addSubRows((rows) => {
                addUnassignedAccounts(accounts, rows, previousYearKey);
                return rows.build();
              });

            return rows.build();
          })
          .addRowWithType(
            'sumEquityAndLiabilities',
            'sum',
            value('Summa eget kapital och skulder'),
            refs(),
            ref(
              sum(
                id(`${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.year`),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.liabilities.*Sum.year`
                )
              )
            ),
            ref(
              sum(
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.liabilities.*Sum.previousYear`
                )
              )
            )
          )
          .build()
      )
      .build(),
  },
});

export const balanceSheetReferences = (): Record<string, string> => ({
  // These references are not used in digital submission yet, but I tried to create element names
  // similar to the ones we have for ABs which are accepted by Bolagsverket
  'balanceSheet.sumEquityAndLiabilities.year': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.year'
  ),
  'balanceSheet.sumEquityAndLiabilities.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.previousYear'
  ),
  'balanceSheet.sumAssets.year': id(
    'balanceSheet.section.assets.sumAssets.year'
  ),
  'balanceSheet.sumAssets.previousYear': id(
    'balanceSheet.section.assets.sumAssets.previousYear'
  ),
  'balanceSheet.ImmateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.intangibleAssets.1.year'
  ),
  'balanceSheet.ByggnaderMarkanlaggningar.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.intangibleAssets.2.year'
  ),
  'balanceSheet.MarkSomInteSkrivas.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.intangibleAssets.3.year'
  ),
  'balanceSheet.MaskinerInventarier.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.intangibleAssets.4.year'
  ),
  'balanceSheet.OvrigaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.intangibleAssets.5.year'
  ),
  'balanceSheet.Varulager.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.inventory.1.year'
  ),
  'balanceSheet.Kundfordringar.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.inventory.2.year'
  ),
  'balanceSheet.OvrigaFordringar.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.inventory.3.year'
  ),
  'balanceSheet.KassaBank.year': id(
    'balanceSheet.section.assets.assets.fixedAndCurrentAssets.inventory.4.year'
  ),
  'balanceSheet.EgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.1.year'
  ),
  'balanceSheet.Laneskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.liabilities.liabilities.1.year'
  ),
  'balanceSheet.Skatteskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.liabilities.liabilities.2.year'
  ),
  'balanceSheet.Leverantorsskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.liabilities.liabilities.3.year'
  ),
  'balanceSheet.OvrigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.liabilities.liabilities.4.year'
  ),
});
