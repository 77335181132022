import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Card, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash';

import { useSelector } from 'redux/reducers';
import {
  getOrganisationMembers,
  addGlobalErrorMessage,
  getClient,
} from 'redux/actions';
import { ClientInformation, ClientInformationLight } from '@agoy/api-sdk-core';
import { ClientInfoContext } from '_clients/context/ClientInformationContext';

import Button from '_shared/components/Buttons/Button';
import { asResultClass, useApiSdk } from 'api-sdk';
import ClientContainer from '../ClientContainer';
import SaveChanges from '../SaveChanges';
import {
  calcTouchedFields,
  createAuditFirm,
  createPerson,
  getContent,
  normalizeString,
} from './utils';
import { defaultClientInformation } from './types';
import { ClientValue } from './ClientCard/CardContent.types';
import ClientCard from '../ClientCard';
import Content from './ClientCard/ClientCardContent';

const CommonCard = styled(Card)`
  display: flex;
  flex-direction: column;
  font-family: 'Exo 2';
  flex: 1;
  min-width: 520px;
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(4)}px;
  &[data-type='generalInfo'] {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
  &[data-type='boardInfo'] {
    background-color: ${(props) => props.theme.palette.clientCard.boardCard};
  }
  &[data-type='contactInfo'] {
    background-color: ${(props) =>
      props.theme.palette.clientCard.contactInfoCard};
  }
  &[data-type='orgInfo'] {
    background-color: ${(props) =>
      props.theme.palette.clientCard.organisationCard};
  }
`;

const FullHeightCustomerContainer = styled(ClientContainer)`
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

const CardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: -${(props) => props.theme.spacing(1)}px;
  > * {
    margin: ${(props) => props.theme.spacing(1)}px;
  }
`;

interface ClientInformationProps {
  clientId: string;
}

const ClientInformationPage = ({
  clientId,
}: ClientInformationProps): JSX.Element => {
  const sdk = useApiSdk();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { fetchClientInformation, patchClientInformation, clientInformation } =
    useContext(ClientInfoContext);

  const orgMembers = useSelector((state) => state.organisation.users);
  const [hasMovedAccounts, setHasMovedAccounts] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultClientInformation,
  });
  const { formatMessage } = useIntl();

  const [edit, setEdit] = useState(false);

  // This is the stored information to compare with when calculating the
  // changes from the form.
  const [storedClientInformation, setStoredClientInformation] = useState<
    ClientInformation | undefined
  >();

  const [currentClientInformation, setCurrentClientInformation] = useState<
    ClientInformation | undefined
  >(undefined);

  const cards = useMemo(
    () => ({
      generalInfo: getContent('generalInfo', currentClientInformation),
      boardInfo: getContent('boardInfo', currentClientInformation),
      contactInfo: getContent('contactInfo', currentClientInformation),
      orgInfo: getContent('organisation', currentClientInformation),
    }),
    [currentClientInformation]
  );

  useEffect(() => {
    fetchClientInformation(clientId);
  }, [clientId, fetchClientInformation]);

  useEffect(() => {
    const clientInfoData = clientInformation[clientId];

    if (clientInfoData) {
      setCurrentClientInformation(clientInfoData);
      setStoredClientInformation((value) =>
        isEqual(value, clientInfoData) ? value : clientInfoData
      );
    }
  }, [clientInformation, clientId]);

  const getMovedAccounts = useCallback(async () => {
    const result = await asResultClass(
      sdk.getCustomAccountGroups({
        clientid: clientId,
      })
    );

    if (!result.ok) {
      return;
    }

    const hasMoved = result.val.some(
      (v) => !!v.modifiedGroups && Object.keys(v.modifiedGroups).length > 0
    );
    setHasMovedAccounts(hasMoved);
  }, [clientId, sdk]);

  useEffect(() => {
    getMovedAccounts();
  }, [getMovedAccounts]);

  const resetForm = useCallback(() => {
    if (!storedClientInformation) {
      return;
    }
    const {
      name,
      type,
      email,
      city,
      ceo,
      directors,
      auditors,
      auditFirm,
      otherFunctions,
      address,
      orgNumber,
      zipCode,
      contactPerson,
      phoneNumber,
      activityText,
      clientManager,
      closingPeriod,
      vatPeriod,
      closingMonth,
      startDateAgoy,
      managerRole2,
      managerRole3,
      sni,
      vatRegistration,
      vatRegistrationDate,
      numberOfEmployees,
      incorporationDate,
      registeredEmployer,
    } = storedClientInformation;

    reset({
      ...defaultClientInformation,
      orgNumber: orgNumber?.value || '',
      name: name?.value || '',
      type: type?.value as ClientInformationLight['type'],
      address: address?.value || '',
      email: email?.value || '',
      city: city?.value || '',
      zipCode: zipCode?.value || '',
      ceo: ceo?.value,
      auditFirm: auditFirm?.value,
      contactPerson: contactPerson?.value || '',
      phoneNumber: phoneNumber?.value || '',
      activityText: activityText?.value || '',
      closingPeriod: closingPeriod?.value,
      vatPeriod: vatPeriod?.value,
      closingMonth: closingMonth?.value,
      clientManager: clientManager?.value,
      startDateAgoy: startDateAgoy?.value,
      managerRole2: managerRole2?.value,
      managerRole3: managerRole3?.value,
      sni: sni?.value,
      vatRegistration: vatRegistration?.value,
      vatRegistrationDate: vatRegistrationDate?.value,
      directors: directors?.value,
      auditors: auditors?.value,
      otherFunctions: otherFunctions?.value,
      numberOfEmployees: numberOfEmployees?.value,
      incorporationDate: incorporationDate?.value,
      registeredEmployer: registeredEmployer?.value,
    });

    setCurrentClientInformation(clientInformation[clientId]);
  }, [storedClientInformation, reset, clientInformation, clientId]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (orgMembers.length === 0) {
      dispatch(getOrganisationMembers());
    }
  }, [dispatch, orgMembers]);

  const handleChange = (field, value) => {
    setValue(field, value);
    setCurrentClientInformation((currentValue) => ({
      ...currentValue,
      [field]: {
        ...currentValue?.[field],
        value,
      },
    }));
  };

  const toggleEdit = () => setEdit((currentValue) => !currentValue);

  const discardChanges = () => {
    toggleEdit();
    resetForm();
  };

  const onSubmit = async (data: ClientInformationLight) => {
    const { auditFirm, auditors } = data;
    const responsibleAuditor = auditors?.find(
      (auditor) => auditor.responsibleAuditor
    );

    if (
      !!auditFirm?.[0] &&
      auditFirm[0].responsibleAuditor &&
      !responsibleAuditor
    ) {
      setError('responsibleAuditor', { type: 'required' });
      return;
    }

    if (storedClientInformation) {
      try {
        const result = await patchClientInformation(
          clientId,
          calcTouchedFields(data, storedClientInformation),
          'user'
        );

        if (result.ok) {
          dispatch(getClient(clientId));
          fetchClientInformation(clientId);
          toggleEdit();
        } else {
          dispatch(addGlobalErrorMessage('error'));
        }
      } catch (e) {
        dispatch(addGlobalErrorMessage('error'));
      }
    }
  };

  const addPerson =
    (content: ClientInformationLight) => (role: string, field: string) => {
      const newItem =
        field === 'auditFirm' ? createAuditFirm(role) : createPerson(role);

      if (['auditFirm', 'ceo'].includes(field)) {
        handleChange(field, [newItem]);
        return;
      }

      handleChange(field, [...(content[field]?.value ?? []), newItem]);
    };

  const deletePerson = (field: string, value: ClientValue, index = 0) => {
    if (Array.isArray(value)) {
      handleChange(
        field,
        value.filter((p, i) => i !== index)
      );
    }
  };

  const companyType = currentClientInformation?.type?.value;
  const directorsType =
    companyType === 'limited' || companyType === 'limited_partnership'
      ? 'partners'
      : 'directors';

  return (
    <FullHeightCustomerContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ButtonWrapper>
          {edit ? (
            <SaveChanges
              type="submit"
              saveLabel={intl.formatMessage({ id: 'save.changes' })}
              discardLabel={intl.formatMessage({ id: 'discard.changes' })}
              discardChanges={discardChanges}
            />
          ) : (
            <Button
              label={intl.formatMessage({ id: 'edit' })}
              variant="outlined"
              color="primary"
              onClick={toggleEdit}
              testId="customer-edit-btn"
            />
          )}
        </ButtonWrapper>

        <CardsWrapper>
          {Object.entries(cards).map(([dataType, content]) => (
            <CommonCard key={dataType} data-type={dataType}>
              <ClientCard
                label={formatMessage({
                  id: `clientInformation.card.${dataType}.label`,
                })}
              >
                <Grid container direction="column">
                  {Object.keys(content).map((key) => (
                    <Content
                      key={key}
                      field={key}
                      control={control}
                      register={register}
                      errors={errors}
                      setValue={handleChange}
                      setError={setError}
                      clearErrors={clearErrors}
                      directorsType={
                        dataType === 'boardInfo' ? directorsType : undefined
                      }
                      orgMembers={orgMembers}
                      hasMovedAccounts={hasMovedAccounts}
                      content={content}
                      value={content[key]?.value}
                      edit={edit}
                      addPerson={addPerson(content)}
                      deletePerson={deletePerson}
                    />
                  ))}
                </Grid>
              </ClientCard>
            </CommonCard>
          ))}
        </CardsWrapper>
      </form>
    </FullHeightCustomerContainer>
  );
};

export default ClientInformationPage;
