import React, { useContext } from 'react';
import {
  CoverLetterFields,
  CoverLetterPart,
  TaxDeclarationFormPartData,
} from '@agoy/tax-declaration-document';
import { useSelector } from 'redux/reducers';

import PrintStateContext from '_annual-report/components/AnnualReportView/PrintStateContext';
import CoverLetter from './CoverLetter';

export const CoverLetterPrint = () => {
  const { state } = useContext(PrintStateContext);
  if (!state.additionalPages) {
    return null;
  }
  const { logo, orgName } = state.additionalPages;

  const fields = state.additionalPages.document.CL.fields as CoverLetterFields;

  return <CoverLetter fields={fields} logo={logo} orgName={orgName} />;
};

type CoverLetterWebProps = {
  document: TaxDeclarationFormPartData | undefined;
};

function isCoverLetterPart(
  part: TaxDeclarationFormPartData
): part is CoverLetterPart {
  return (part as CoverLetterPart).partType === 'coverLetter';
}

export const CoverLetterWeb = ({ document }: CoverLetterWebProps) => {
  const organisation = useSelector((state) => state.organisation);

  const { logo, name: orgName } = organisation;

  if (!document || !isCoverLetterPart(document)) {
    return null;
  }

  const { fields } = document;

  return (
    <CoverLetter fields={fields} logo={logo ?? ''} orgName={orgName ?? ''} />
  );
};
